<template>
	<div class="px-2 sm:px-10 py-4">
		<Header :title="content.title" :description="content.description" :profile="{
			avatar: get(state.currentUser, 'avatar'),
			name: get(state.currentUser, 'firstName', '') + ' ' + get(state.currentUser, 'lastName', ''),
		}"/>
		<div class="mt-10">
			<EmptyState v-if="content.ideas.length === 0" class="bg-white" isFull v-bind="content.empty" :action="() => isShowIdea = true"/>
			<div v-else>
				<ListViewHeader v-bind="content.ideaHeader" @onClick="() => isShowIdea = true"/>
				<div class="space-y-3 mt-5">
					<CardWithBadge v-for="idea in content.ideas" :key="idea.id" v-bind="idea"/>
					<Text size="sm" color="primary-600" content="View all" decoration="underline" custom-class="cursor-pointer"/>
				</div>
			</div>
		</div>
		<div class="mt-10">
			<ListViewHeader title="Resources"/>
			<div class="space-y-3 mt-5">
				<CardWithImage v-for="idea in content.resources" :key="idea.id" v-bind="idea"/>
				<Text size="sm" color="primary-600" content="More Resources" decoration="underline"
							custom-class="cursor-pointer"/>
			</div>
		</div>
		<div class="mt-10">
			<ListViewHeader title="Your Ecosystem"/>
			<div class="space-y-3 mt-5">
				<CardWithBadge v-for="idea in content.ecosystems" :key="idea.id" v-bind="idea"/>
				<!--					<Text size="sm" color="primary-600" content="More Resources" decoration="underline" custom-class="cursor-pointer"/>-->
			</div>
		</div>
		<WelcomeModal :open="isShow" :content="c.addIdea" v-bind="welcome" @onClose="(isOpen) => onCloseWelcome(isOpen)"/>
		<AddIdea v-bind="{
			...c.addIdea,
			isShow: isShowIdea,
			isLoading: false,
		}"
						 @onClose="() => isShowIdea = false"
		/>
	</div>
</template>

<script>
import Header from '../components/molecules/PageViewHeader/WithAvatar/WithAvatar'
import EmptyState from '../components/organisms/EmptyState/Simple/Simple'
import ListViewHeader from '../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton'
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Text from '../components/atoms/Text/Text';
import {c} from '../components/constants.js';
import WelcomeModal from '../components/organisms/Modals/Large/Large'
import {get} from 'lodash'
import AddIdea from "../components/organisms/Modals/AddIdea/AddIdea";

export default {
	components: {
		AddIdea,
		Header, EmptyState, ListViewHeader, CardWithImage, CardWithBadge, Text, WelcomeModal,
	},
	data() {
		return {
			content: c.home,
			isShow: false,
			isShowIdea: false,
			welcome: c.welcome,
			get,
			c
		}
	},
	mounted() {
    this.isShow = this.state.currentUser?.metadata?.firstLogin
	},
	methods: {
		async onCloseWelcome(isOpen) {
			try {
				this.isShow = false;
				await this.actions.user.saveUser({
          where: {id: this.state.currentUser?.id},
          data: {
            metadata: {
              ...this.state.currentUser?.metadata,
              firstLogin: false,
            }
          }
        })
				// this.isShowIdea = isOpen;
				// let query = Object.assign({}, this.$route.query);
				// delete query.registered;
				this.$router.push('/');
			} catch (e) {
				console.log(e)
			}
		}
	}
}
</script>
